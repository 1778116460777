(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-quickplay/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-quickplay/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const isServer = typeof exports === 'object';

const trinidad = isServer ? require('trinidad-core').core : svs.core;

const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-quickplay');
const performanceLogger = getLogger('banner-grid:performance');

const {
  resolveProductId,
  getDrawName,
  productIds
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;

const {
  TTLCacheAsync
} = isServer ? require('../../../../lb-utils/ttl-cache/controller.js') : svs.components.lbUtils.ttlCache;

const {
  getProductBrand
} = isServer ? require('../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6') : svs.components.lbUtils.brandMapping.fn;

const {
  DrawState
} = isServer ? require('../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6') : svs.components.sport.tipsenShared;
const ONE_MINUTE = 60 * 1000;
const TIMEOUT_FOR_JUPITER_CALLS = 3000;
const mockableHelpers = Object.seal({
  _queryDraw
});
const Global = Object.seal({
  drawNumberCache: new TTLCacheAsync({
    onRefreshAsync: inputBag => mockableHelpers._queryDraw(inputBag),
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("drawNumberCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  })
});

async function renderComponent(bannerItem, widgetInfo, position, req, res) {
  var _bannerItem$images, _bannerItem$images2, _svs;
  if (!Object.keys(bannerItem.layoutConfig).length) {
    return '';
  }
  const {
    brandingAndSize,
    drawNumber: drawNumberConfig,
    layoutVariant,
    splashColor
  } = bannerItem.layoutConfig;
  const [branding, systemCostString] = String(brandingAndSize).split('-');
  const feature = svs.isServer ? req.feature : svs.core.detect.feature;
  const hvsClose = svs.isServer ? await feature('ft-hvs-close') : svs.core.detect.feature('ft-hvs-close');
  if (hvsClose && resolveProductId(branding) === productIds.FULLTRAFF) {
    return '';
  }
  const systemCost = parseFloat(systemCostString.replace(',', '.'));
  const hasBlackText = String(layoutVariant).includes('black-text');
  const hasImage = String(layoutVariant).includes('with-image');
  const landscapeImage = hasImage ? (_bannerItem$images = bannerItem.images) === null || _bannerItem$images === void 0 ? void 0 : _bannerItem$images.filter(item => item.typeName === 'productImage')[0] : null;
  const portraitImage = hasImage ? (_bannerItem$images2 = bannerItem.images) === null || _bannerItem$images2 === void 0 ? void 0 : _bannerItem$images2.filter(item => item.typeName === 'productImageOptional')[0] : null;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-griditem_quickplay-item'] :
  svs.banner_layouts.griditem_quickplay.templates.item;
  const isAdmin = isServer ?
  req.userSession.hasRole(req.userSession.roles.INTERNAL) :
  svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  const trackingStringWithFixedStructure = [widgetInfo.widgetType, widgetInfo.trackingId || widgetInfo.widgetName, position, bannerItem.layoutType, bannerItem.title].join('/');
  const t0 = performance.now();
  const cachedData = await Global.drawNumberCache.readAsync({
    key: "".concat(branding, "_").concat(drawNumberConfig || 'auto'),
    payload: {
      drawNumberConfig,
      req
    }
  });
  performanceLogger.info("drawNumberCache.readAsync() took ".concat(Math.round(performance.now() - t0), " milliseconds (index ").concat(position - 1, ")"));
  const drawNumber = cachedData === null || cachedData === void 0 ? void 0 : cachedData.drawNumber;
  const productId = cachedData === null || cachedData === void 0 ? void 0 : cachedData.productId;
  const rowPrice = (cachedData === null || cachedData === void 0 ? void 0 : cachedData.rowPrice) || 1;
  const userProduct = cachedData === null || cachedData === void 0 ? void 0 : cachedData.userProduct;
  if (!productId || !drawNumber) {
    return '';
  }
  const productBrand = getProductBrand(productId, null, userProduct);
  return hbsCompiled(_objectSpread(_objectSpread({}, bannerItem.layoutConfig), {}, {
    isAdmin,
    bannerId: bannerItem.id,
    branding: productBrand ? productBrand : branding,
    productId,
    drawNumber,
    systemCost,
    hasBlackText,
    features: isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features,
    splashColor: splashColor === '-' ? null : splashColor,
    landscapeImage,
    portraitImage,
    rowPrice,
    trackingString: trackingStringWithFixedStructure,
    rows: Math.floor(systemCost / rowPrice),
    crm: bannerItem.targetOffer
  })) || '';
}

async function _queryDraws(productId, req) {
  const path = "/draw/1/".concat(getDrawName(productId), "/draws");
  const queryString = '?include=draws.drawNumber%2Cdraws.regCloseTime%2Cdraws.productId%2Cdraws.rowPrice%2Cdraws.userProduct%2Cdraws.drawStateId';
  const t0 = performance.now();
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path: path + queryString,
    req
  });
  performanceLogger.info("jupiter.callAsync() took ".concat(Math.round(performance.now() - t0), " milliseconds (productId ").concat(productId, ")"));
  const {
    error,
    draws
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing current draws (product ".concat(productId, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(draws) || draws.length === 0) {
    logger.info("Missing \"draws\" in response from draw-API (product ".concat(productId, ")"));
    return {};
  }
  const filteredDraws = draws.filter(draw => draw.drawStateId === DrawState.Opened);
  return {
    draws: filteredDraws
  };
}

async function _queryDraw(_ref) {
  let {
    key,
    payload
  } = _ref;
  const {
    req,
    drawNumberConfig
  } = payload;
  const branding = key.slice(0, key.indexOf('_'));
  const productId = resolveProductId(branding);
  const {
    draws
  } = await _queryDraws(productId, req);
  if (!draws || draws.length === 0) {
    return {};
  }
  let earliestRegCloseTimeTS = null;
  let drawNumber;
  let dataProductId;
  let rowPrice;
  let userProduct;
  draws.forEach(item => {
    let isPossiblyWantedItem = false;

    if (drawNumberConfig && parseInt(drawNumberConfig, 10) === item.drawNumber) {
      isPossiblyWantedItem = true;
    }
    if (!drawNumberConfig && item.regCloseTime) {
      const regCloseTimeTS = new Date(item.regCloseTime).getTime();
      const hasEarlierRegCloseTime = earliestRegCloseTimeTS === null || regCloseTimeTS < earliestRegCloseTimeTS;
      if (hasEarlierRegCloseTime) {
        earliestRegCloseTimeTS = regCloseTimeTS;
        isPossiblyWantedItem = true;
      }
    }
    if (isPossiblyWantedItem) {
      drawNumber = item.drawNumber;
      dataProductId = item.productId;
      rowPrice = parseFloat(String(item.rowPrice).replace(',', '.'));
      userProduct = item.userProduct;
    }
  });
  return {
    drawNumber,
    productId: dataProductId,
    rowPrice,
    userProduct
  };
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.griditem_quickplay', {
    renderComponent
  });

  const isTestEnvironment = typeof sinon !== 'undefined' && typeof describe !== 'undefined';
  if (isTestEnvironment) {
    setGlobal('svs.banner_layouts.griditem_quickplay._testInternals', {
      getMockableHelpers: () => mockableHelpers
    });
  }
}

 })(window);